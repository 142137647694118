@import "../styles/vars.scss";

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -3rem;
    top: 0;
    background-color: $orange-500;
    height: 5rem;
    width: 5rem;
    font-family: $secondary-font-family;
    font-weight: bold;
    color: #000;
    font-size: 1.8rem;
    text-transform: uppercase;
}