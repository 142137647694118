@import "./styles/vars.scss";

body {
    overscroll-behavior: none;
}

html {
    font-size: 62.5%;

    @media (min-width: $breakpoint-tablet) {
        font-size: 80%;
    }

    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $primary-color;
    font-family: $primary-font-family;
    font-weight: $regular;
    font-size: $small-text-size;
    overflow-x: hidden;
}

.title {
    font-family: $secondary-font-family;
    font-weight: $semibold;
    font-size: $title-size;
    margin: 0;
    margin-bottom: 3rem;
}

.subtitle {
    font-family: $secondary-font-family;
    font-weight: $medium;
    font-size: $subtitle-size;
    color: $secondary-color;


    &.page-subtitle {
        margin: 0;
        margin-bottom: 2.5rem;
        color: $grey-700;
    }
}

.text {
    font-size: $text-size;
}

.alt-color {
    color: $bg-color;
}

.alt-all {
    color: #000000;
    background-color: $bg-alt-color;
}

.unselected {
    color: $unselected-color;
}

.page {
    width: 100vw;
    background-color: $bg-color;
    padding: ($header-height + 5rem) $page-horizontal-padding 3rem $page-horizontal-padding;

    &.alt-color {
        background-color: $bg-alt-color;
    }

    &.whiteBg {
        background-color: $bg-white;
    }

    &.timeline-container {
        padding-top: ($header-height);
        padding-left: 0;
    }
}


button {
    border: none;
    background-color: inherit;
    color: $primary-color;
    font-family: $secondary-font-family;
    margin: 0;
}


audio {
    display: none;
}

.slider-track {
    /* Top value to align your track to the center of your thumb */
    top: -3px;
    height: 2px;
    background: $unselected-color;
}

.slider-track.slider-track-0 {
    /* color of the track before the thumb */
    background: $bg-color;
}

.page-timeline {
    width: 100vw;
    background-color: $bg-color;
    padding: ($header-height) 2rem 0 0;
    height: 100vh;
}

.wrapper {
	margin: 0 auto;
	padding: 0 16.66% 50px;
	width: 100%;

    .milestone{
        padding-top: 30vh;
        margin: 0;
    }

    div{
        padding-bottom: 30vh;
        padding-left: 3rem;
    }
}

article {
	position: relative;
	max-width: 980px;
	margin: 0 0;
}

.timeline {
	&__nav {
		position: fixed;
		z-index: 99;
		top: 0;
		transition: top .3s ease-out;
        margin-left: 0;

		ul {
			list-style: none;
			list-style-position: inside;
			margin: 15px 0;
            padding: 2rem;
            padding-left: 6rem;
            background-color: #000000;

			li {
				margin: 15px 0;
				padding-left: 0;
				list-style-type: none;
				color: #888888;
				border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
				cursor: pointer;
				transition: all .3s ease-out;
				
				&.active {
					font-weight: bold;
					color: #fff;
					border-bottom: 1px dotted transparent;
					transform: scale(1.5);
				}

			}
		}
	}
}
