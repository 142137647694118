@import "../styles/vars.scss";

.wrapper {
    padding-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: -$page-horizontal-padding;
    background-image: url(../assets/images/icons/bg-footer.svg);
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 100%;
    background-size: 26rem 10rem;
    margin-right: -3rem;
    padding-right: 3rem;
    overflow: visible;
    height: 9rem;
    margin-top: 6rem;
}

.icon {
    margin-left: 2rem;
}
