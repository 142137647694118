@import "../styles/vars.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $secondary-font-family;
    font-weight: $light;
    margin-bottom: 3rem;
    background-color: $bg-color;
    position: relative;

    .box {
        width: 100%;
        min-height: 21rem + 2.3rem;
        border: 1px solid $secondary-color;
        padding: 2rem;
    }
}

.title {
    font-size: 2.4rem;   
}

.subtitle {
    font-size: 1.6rem;
    font-family: $secondary-font-family;
    font-weight: $light;
    color: $grey-700;
}


.divNext {
    position: absolute;
    bottom: 1rem;
    right: 2rem;
}
