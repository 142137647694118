@import "../styles/vars.scss";

.wrapper {
    position: relative;
    margin-bottom: 4rem;
}

.icon {
    position: absolute;
    $size: 2.4;
    left: -#{$size + 1}rem;
    width: #{$size}rem;
    height: #{$size}rem;
}

.title {
    color: $primary-color;
    padding-right: 2rem;
}

.description {
    font-family: $primary-font-family;
    color: $grey-700;
    margin-bottom: 1.5rem;
    margin-right: 3rem;
    font-size: 1.3rem;
}

.button {
    display: flex;
    flex-direction: row;
    padding: 0.8rem 1.6rem;
    border: 0.5px solid $primary-color;
    border-bottom: 0;
    margin-bottom: 0;
    align-items: center;
    gap: .4rem;
    
    img {
        $size: 1.8rem;
        width: $size;
        height: $size;
    }
}


.readMore {
    font-family: $secondary-font-family;
    font-weight: $bold;
    text-transform: uppercase;
    color: #000
}

.playButton{
    font-family: $secondary-font-family;
    font-weight: $bold;
    text-transform: uppercase;
}

.line {
    margin-top: -1px;
    margin-right: -$page-horizontal-padding;
}

.buttonWrapper{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

