@import "../styles/vars.scss";

.page {
    padding-left: $page-horizontal-padding * 2;

    .social {
        margin-left: -$page-horizontal-padding * 2;
    }
}

.line {
    margin-right: -$page-horizontal-padding;
}

.image {
    width: calc(100% + $page-horizontal-padding * 2);
    margin-left: -$page-horizontal-padding * 2;
    object-fit: cover;
    margin-bottom: 6rem;
}

.text {
    margin-top: 2rem;
    margin-bottom: 4rem;
}
