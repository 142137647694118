@import "../styles/vars.scss";

.header {
    position: fixed;
    width: 100vw;
    height: $header-height;  // TODO mai: su desktop è troppo alto
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2.4rem 2rem;
    background-color: $bg-color;
    border-bottom: .5px solid $secondary-color;
    z-index: 100;
}

.alt {
    background-color: $primary-color;
    color: $bg-color;
    border-bottom-color: $primary-color;
}

.logo {
    position: absolute;
    width: 13.0rem;
    height: 4.4rem;
    left: 50%;
    transform: translateX(-50%);
}

.imgButton {
    width: 2.4rem;
    height: 2.4rem;
}

.buttonHeader {
    color: $secondary-color;
}
