@import "../styles/vars.scss";

.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.button {
    font-size: 3.6rem;
    margin: 1.5rem 0;
}

.button:first-of-type {
    margin-top: 4rem;
}

.button:last-of-type {
    margin-bottom: 10.9rem;
}
