@import "../styles/vars.scss";

.page {
    padding-left: $page-horizontal-padding*2;

    .social {
        margin-left: -$page-horizontal-padding*2;
    }
}
.articleTitle{
    margin-bottom: 2rem;
}

.line {
    margin-right: -$page-horizontal-padding;
}

.image {
    width: calc(100% + $page-horizontal-padding*2);
    margin-left: -$page-horizontal-padding*2;
    object-fit: cover;
    margin-bottom: 3rem;
}

.text {
    margin: 2rem 0 4rem -2rem;
}
